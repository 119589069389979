import './nav.css'
import { Link } from "react-router-dom"

function Footer() {
    return (
        <div className='footer'>
            <div className='footer-linkbox'>
                <div className='wrap'>
                    <ul>
                        <li className='footer-wrap-li'>
                            <Link to={`/term`} style={{ textDecoration: "none", color: "black" }} className='footer-wrap-li-text'>서비스 이용약관</Link>
                        </li>
                        <li className='footer-wrap-li'>
                            <Link to={`/privacy`} style={{ textDecoration: "none", color: "black" }} className='footer-wrap-li-text'>개인정보 처리방침등</Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='footer-infobox'>
                <div className='footer-bottom'>
                    <h1>MHPAY</h1>
                    <p className='footer-bottom-text'>서울특별시 서초구 나루터로 10길 20(잠원동)<br />
                        회사명 : (주) 엠에이치퓨쳐스 대표자 : 최재호 사업자등록번호 : 105-86-11164 통신판매업신고번호 : <br />
                        Copyright ⓒ 2023 MHFutures. All rights reserved.
                        <span>Inspired by MHPAY</span>
                    </p>
                    <div className='footer-bottom-snsbox'>
                        <a href='https://blog.naver.com/starbank-' target="_blank" className='btn-facebook' style={{backgroundImage:'url(/img/naver_blog.png)'}}></a>
                        {/* <a href='' onClick={() => alert('준비중입니다.')} className='btn-instagram' style={{backgroundImage:'url(/img/img_instagram.png)'}}></a> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;